import {
  SetHeroAirOceanFormDataPayload,
  SetHeroIntShoppingFormDataPayload,
  SetHeroLastMileFormDataPayload,
  SetHeroTrackShipmentFormDataPayload,
  FORMS_HERO_TRACK_SHIPMENT,
  SetHeroTrackShipmentFormData,
  FORMS_HERO_AIR_OCEAN,
  SetHeroAirOceanFormData,
  FORMS_HERO_LAST_MILE,
  SetHeroLastMileFormData,
  FORMS_HERO_INT_SHOPPING,
  SetHeroIntShoppingFormData,
  HeroAction,
  HERO_ACTIVE_TAB_ID,
  HERO_ACTIVE_CATEGORY_ID,
  SetHeroActiveCategoryId,
  SetHeroActiveTabId,
} from './actions';

import {
  buildTrackUrl, buildQuoteUrl, buildCreateAccountUrl, buildFreeAddressUrl,
} from './middleware';

export type HeroState = {
  heroActiveTabId: string | null;
  heroActiveCategoryId: string | null;
  mobileHeroActiveTabId: string | null;
  mobileHeroActiveCategoryId: string | null;
  heroTrackShipmentFormData: SetHeroTrackShipmentFormDataPayload;
  heroAirOceanFormData: SetHeroAirOceanFormDataPayload;
  heroLastMileFormData: SetHeroLastMileFormDataPayload;
  heroIntShoppingFormData: SetHeroIntShoppingFormDataPayload;
};

export const initialState: HeroState = {
  heroActiveTabId: null,
  heroActiveCategoryId: null,
  mobileHeroActiveTabId: '1',
  mobileHeroActiveCategoryId: '1',
  heroTrackShipmentFormData: {
    trackingNumber: {
      value: undefined,
      validationError: undefined,
    },
    trackUrl: buildTrackUrl(),
  },
  heroAirOceanFormData: {
    origin: undefined,
    destination: undefined,
    quoteUrl: buildQuoteUrl(),
  },
  heroLastMileFormData: {
    firstName: undefined,
    email: undefined,
    createAccountUrl: buildCreateAccountUrl(),
  },
  heroIntShoppingFormData: {
    email: undefined,
    country: undefined,
    freeAddressUrl: buildFreeAddressUrl(),
  },
};

const heroReducer = (
  state: HeroState = initialState,
  action: HeroAction,
): HeroState => {
  switch (action.type) {
    case HERO_ACTIVE_TAB_ID: {
      const field = action.isMobile
        ? 'mobileHeroActiveTabId'
        : 'heroActiveTabId';
      return {
        ...state,
        [field]: (action as SetHeroActiveTabId).payload,
      };
    }
    case HERO_ACTIVE_CATEGORY_ID: {
      const field = action.isMobile
        ? 'mobileHeroActiveCategoryId'
        : 'heroActiveCategoryId';

      return {
        ...state,
        [field]: (action as SetHeroActiveCategoryId).payload, // eslint-disable-line
      };
    }
    case FORMS_HERO_TRACK_SHIPMENT:
      return {
        ...state,
        heroTrackShipmentFormData: (
          action as SetHeroTrackShipmentFormData
        ).payload,
      };
    case FORMS_HERO_AIR_OCEAN:
      return {
        ...state,
        heroAirOceanFormData: (
          action as SetHeroAirOceanFormData
        ).payload,
      };
    case FORMS_HERO_LAST_MILE:
      return {
        ...state,
        heroLastMileFormData: (
          action as SetHeroLastMileFormData
        ).payload,
      };
    case FORMS_HERO_INT_SHOPPING:
      return {
        ...state,
        heroIntShoppingFormData: (
          action as SetHeroIntShoppingFormData
        ).payload,
      };
    default:
      return state;
  }
};

export default heroReducer;
