import styled from 'styled-components';
import { fonts, media } from 'src/views/theme';
import { TabPanelTheme } from '@shipae/components-sandbox/component/tabs/panel';
import NeutralMultiInputTheme
  from '@shipae/components-sandbox/component/multi-input/theme/neutral';
import NeutralInputTheme
  from '@shipae/components-sandbox/component/input/theme/neutral';
import NeutralFormElementTheme
  from '@shipae/components-sandbox/component/form-element/theme/neutral';
import NeutralSelectTheme
  from '@shipae/components-sandbox/component/select/theme/neutral';

export const PanelTheme = {
  ...TabPanelTheme,
  Main: styled(TabPanelTheme.Main)`
    padding: 4.8rem 0.8rem 0 0;
    width: 100%;

    ${ media.md } {
      padding: 4.8rem 1.6rem 0;
    }

    ${ media.sm } {
      padding: 1.6rem 0 0 0;
    }
  `,
};

export const Title = styled.h3`
  ${ fonts.displays200 };
`;

export const InputTheme = NeutralInputTheme;
export const MultiInputTheme = NeutralMultiInputTheme;
export const SelectTheme = NeutralSelectTheme;
export const FormElementTheme = NeutralFormElementTheme;
