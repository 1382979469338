import React from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  setHeroTrackShipmentFormData,
} from 'src/application/lazy/hero/actions';
import {
  getHero,
} from 'src/application/lazy/hero/selectors';
import TabPanel, {
  TabPanelPropsType,
} from '@shipae/components-sandbox/component/tabs/panel';
import Multiinput from '@shipae/components-sandbox/component/multi-input';
import FormElement from '@shipae/components-sandbox/component/form-element';
import { PrimaryButton } from '@shipae/components-sandbox/component/buttons';
import { trackHeroTrackSuccess } from 'application/core/analytics/actions';
import { useTranslation } from 'src/views/misc/localization';
import SmartLink from 'components/smart-link';
import { Title, PanelTheme, MultiInputTheme } from '../styles';
import { FormElTheme } from './styles';

type TrackShipmentProps = {
  id: string,
  title: string,
  PanelContainer?: React.FC<TabPanelPropsType>,
};
const TrackShipment: React.FC<TrackShipmentProps> = ({
  id,
  title,
  PanelContainer = TabPanel,
}) => {
  const dispatch = useDispatch();
  const { heroTrackShipmentFormData: formData } = useSelector(getHero);
  const { trackingNumber, trackUrl } = formData;
  const { t, locale } = useTranslation();
  const trackUrlWithLocale = locale === 'ar' ? `/ar${ trackUrl }` : trackUrl;

  return (
    <PanelContainer id={id} theme={PanelTheme}>
      <Title>{t(title)}</Title>
      <FormElement
        label={t('enterTrackingNumber')}
        theme={FormElTheme}
        error={trackingNumber?.validationError}
        hasValue={!!trackingNumber.value}
      >
        <Multiinput
          onChange={(input: string[]) => {
            dispatch(setHeroTrackShipmentFormData({
              ...formData,
              trackingNumber: {
                ...trackingNumber,
                value: input.join(','),
              },
            }));
          }}
          separators={[',', ' ']}
          theme={MultiInputTheme}
        />
      </FormElement>
      <PrimaryButton
        href={trackUrlWithLocale}
        disabled={!!trackingNumber?.validationError}
        linkComponent={SmartLink}
        onClick={() => dispatch(trackHeroTrackSuccess)}
      >
        {t('track')}
      </PrimaryButton>
    </PanelContainer>
  );
};

export default TrackShipment;
