import React from 'react';
import Loadable from 'react-loadable';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'src/views/misc/localization';

import {
  setHeroActiveCategoryId,
  setHeroActiveTabId,
} from 'src/application/lazy/hero/actions';
import {
  getHero,
} from 'src/application/lazy/hero/selectors';
import GtArrowIcon from '@shipae/components-sandbox/component/icons/gt-arrow';

import {
  HeroType,
  HeroMenuCategoryType,
  HeroMenuItemType,
} from 'src/infrastructure/gatsby/types/hero';
import TrackingCategory from './categories/tracking';
import {
  Section, Category, CategoryTitle, Loading,
} from './styles';

const HERO_CATEGORIES = [
  TrackingCategory,
  Loadable({
    loader: () => import('./categories/business' /* webpackChunkName: "hero-business" */) as any, // eslint-disable-line
    loading: () => <Loading>Loading...</Loading>,
  }),
  Loadable({
    loader: () => import('./categories/personal' /* webpackChunkName: "hero-personal" */) as any, // eslint-disable-line
    loading: () => <Loading>Loading...</Loading>,
  }),
];

const DATA: HeroMenuCategoryType[] = [
  {
    id: '1',
    title: 'heroTrackingCategoryTitle',
    heroMenuItems: [
      {
        id: '1',
        backgroundImageBase64: null,
        backgroundColor: '#74C6E7',
      },
    ],
  },
  {
    id: '2',
    title: 'heroBusinessCategoryTitle',
    heroMenuItems: [
      {
        id: '2',
        backgroundImageBase64: null,
        backgroundColor: '#A0927F',
      },
      {
        id: '3',
        backgroundImageBase64: null,
        backgroundColor: '#e0d8f5',
      },
      {
        id: '4',
        backgroundImageBase64: null,
        backgroundColor: '#D9C8A3',
      },
    ],
  },
  {
    id: '3',
    title: 'heroPersonalCategoryTitle',
    heroMenuItems: [
      {
        id: '5',
        backgroundImageBase64: null,
        backgroundColor: '#A7BFC8',
      },
      {
        id: '6',
        backgroundImageBase64: null,
        backgroundColor: '#af95c3',
      },
    ],
  },
];

const HeroMobile: React.FC<HeroType> = ({
  sectionId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    mobileHeroActiveCategoryId: activeCategoryId,
    mobileHeroActiveTabId: activeTabId,
  } = useSelector(getHero);
  const ActiveCategory = HERO_CATEGORIES[Number(activeCategoryId) - 1];
  const ActiveCategoryCms = DATA?.find(
    (category: HeroMenuCategoryType) => category.id === activeCategoryId,
  );
  const ActiveTab = ActiveCategoryCms?.heroMenuItems?.find(
    (tab: HeroMenuItemType) => tab.id === activeTabId,
  );
  const image = ActiveTab?.backgroundImageBase64 || '';
  const color = ActiveTab?.backgroundColor || '';

  return (
    <Section
      id={sectionId}
      data-test="section-hero"
      data-ga="Landing Hero"
      bgImage={image}
      bgColor={color}
    >
      {DATA?.map((category: HeroMenuCategoryType) => (
        <Category key={category.id}>
          <CategoryTitle
            onClick={() => {
              dispatch(setHeroActiveCategoryId(category.id, true));
              const firstCategoryTab = category?.heroMenuItems
                && category?.heroMenuItems[0];

              if (firstCategoryTab?.id) {
                dispatch(setHeroActiveTabId(firstCategoryTab.id, true));
              }
            }}
            data-ga-trigger
          >
            {category?.title && t(category.title)}
            <GtArrowIcon
              height={2.4}
              direction={activeCategoryId === category?.id ? 'top' : 'bottom'}
            />
          </CategoryTitle>
          {activeCategoryId === category?.id
            && category?.heroMenuItems
            && <ActiveCategory />}
        </Category>
      ))}
    </Section>
  );
};

export default HeroMobile;
