import React from 'react';

import { ReactComponent as HeroTrackIcon }
  from '@shipae/components-sandbox/component/static-icons/hero-track-icon.svg';

import TrackShipmentForm from '../../panels/track-shipment';
import { PanelContainer } from '../styles';

const PANELS = [
  {
    id: '1',
    tablTitle: 'heroTrackShipmentTabTitle',
    formTitle: 'heroTrackShipmentFormTitle',
    formComponent: 'heroTrackShipment',
    icon: HeroTrackIcon,
  },
];

const Tracking: React.FC = () => {
  const panel = PANELS[0];

  return panel?.id && panel.formTitle ? (
    <TrackShipmentForm
      id={panel.id}
      title={panel.formTitle}
      PanelContainer={PanelContainer}
    />
  ) : null;
};

export default Tracking;
