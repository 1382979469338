import styled from 'styled-components';
import NeutralFormElementTheme
  from '@shipae/components-sandbox/component/form-element/theme/neutral';

export const FormElTheme = {
  ...NeutralFormElementTheme,
  Main: styled(NeutralFormElementTheme.Main)`
    margin-top: 2.4rem;
    width: 100%;
  `,
};
