import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setHeroActiveTabId,
} from 'src/application/lazy/hero/actions';
import {
  getHero,
} from 'src/application/lazy/hero/selectors';
import TabsU from '@shipae/components-sandbox/component/tabs/ustyle';
import { useLocation } from '@reach/router';

import {
  HeroType,
  HeroMenuItemType,
} from 'src/infrastructure/gatsby/types/hero';
import {
  Section,
  MenuWrapper,
  Menu,
  BgImage,
  BgColor,
} from './styles';
import { buildGroups, buildHeroMenuItems } from './service';

const HeroDektop: React.FC<HeroType> = ({
  sectionId,
  HeroMenuCategory,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showNonFirstImage, setShowNonFirstImage] = useState(false);

  /* Delay non-current image for performance */
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNonFirstImage(true);
    }, 8000);
    return () => clearTimeout(timer);
  }, []);

  const {
    heroActiveTabId,
  } = useSelector(getHero);

  useEffect(() => {
    if (heroActiveTabId) {
      dispatch(setHeroActiveTabId(''));
    }
  }, [location.pathname]);
  const groups = useMemo(
    () => buildGroups(HeroMenuCategory || []),
    [HeroMenuCategory],
  );
  const heroMenuItems = useMemo(
    () => buildHeroMenuItems(HeroMenuCategory || []),
    [HeroMenuCategory],
  );

  const renderActivePanel = (tabId: string | null) => {
    let atab = tabId && heroMenuItems
      ? heroMenuItems.find((item: HeroMenuItemType) => item.id === tabId)
      : heroMenuItems[0];

    if (!atab) {
      [atab] = heroMenuItems;
    }

    return (
      <atab.Panel
        id={atab.id}
        title={atab.formTitle || ''}
        key={atab.id}
      />
    );
  };

  return (
    <Section
      id={sectionId}
      data-test="section-hero"
      data-ga="Landing Hero"
    >
      {heroMenuItems.length ? (
        heroMenuItems.map((menuItem, index) => (
          menuItem?.backgroundImage?.localFile?.childImageSharp?.fluid && (
            // always render first tab image and delay non current images
            index === 0 || showNonFirstImage
          ) ? (
            <BgImage
              key={menuItem.id}
              fluid={
                menuItem?.backgroundImage?.localFile?.childImageSharp?.fluid
              }
              alt={menuItem?.backgroundImage?.alternativeText || ''}
              objectFit="cover"
              objectPosition="center center"
              backgroundColor="#74c7e9"
              loading="eager"
              fadeIn={false}
              current={
                heroActiveTabId
                  ? menuItem.id === heroActiveTabId
                  : index === 0
              }
              data-test="bgimage"
            />
            ) : <BgColor color="#74c7e9" key={menuItem.id} />
        ))
      ) : <BgColor color="#74c7e9" key="1234" />}
      <MenuWrapper data-test="hero-menu-wrapper">
        <Menu>
          { groups && heroMenuItems && (
            <TabsU
              groups={groups}
              onChange={(tabId) => dispatch(setHeroActiveTabId(tabId))}
              data-ga-trigger
            >
              {renderActivePanel(heroActiveTabId)}
            </TabsU>
          )}
        </Menu>
      </MenuWrapper>
    </Section>
  );
};

export default HeroDektop;
