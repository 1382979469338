import React from 'react';
import Loadable from 'react-loadable';

import {
  HeroMenuCategoryType,
  HeroMenuItemType,
} from 'src/infrastructure/gatsby/types/hero';

import {
  HeroGroupType,
  HeroMenuItemTypeEx,
} from '..';
import { Icon, Loading } from './styles';
import TrackShipment from '../panels/track-shipment';

export type Panel = any; // eslint-disable-line

const HERO_PANELS: Record<string, Panel> = {
  trackShipment: TrackShipment,
  ecommerceLogistics: Loadable({
    loader: () => import('../panels/ecommerce-logistics') as any, // eslint-disable-line
    loading: () => <Loading>Loading...</Loading>,
  }),
  lastMileDelivery: Loadable({
    loader: () => import('../panels/last-mile-delivery') as any, // eslint-disable-line
    loading: () => <Loading>Loading...</Loading>,
  }),
  airAndOceanFreight: Loadable({
    loader: () => import('../panels/air-ocean-freight') as any, // eslint-disable-line
    loading: () => <Loading>Loading...</Loading>,
  }),
  myCourier: Loadable({
    loader: () => import('../panels/my-courier') as any, // eslint-disable-line
    loading: () => <Loading>Loading...</Loading>,
  }),
  internationalShopping: Loadable({
    loader: () => import('../panels/international-shopping') as any, // eslint-disable-line
    loading: () => <Loading>Loading...</Loading>,
  }),
};

export const buildGroups = (
  heroMenuCategory: HeroMenuCategoryType[],
): HeroGroupType[] => heroMenuCategory.map(
  (group, groupIdx) => ({
    title: group.title || '',
    tabs: group?.heroMenuItems?.map(
      (item: HeroMenuItemType, itemIdx: number) => ({
        id: item.id,
        title: item.tabTitle || '',
        active: groupIdx === 0 && itemIdx === 0,
        icon: (
          <Icon
            style={{
              backgroundImage: `url('${ item?.icon?.localFile?.publicURL }')`,
            }}
            role="img"
            aria-label={item?.icon?.alternativeText || ''}
          />
        ),
        bgImage: item.backgroundImage?.localFile?.childImageSharp?.fluid,
        bgImageAlt: item.backgroundImage?.alternativeText,
      })) || [],
  }),
);

const transformHeroMenuItems = (item: HeroMenuItemType, itemIdx: number) => ({
  ...item,
  id: item.id,
  active: itemIdx === 0,
  Panel: item.formComponent
    ? HERO_PANELS[item.formComponent]
    : <Loading>Tab not found</Loading>,
  bgImage: item.backgroundImage?.localFile?.childImageSharp?.fluid,
  bgImageAlt: item.backgroundImage?.alternativeText,
});

const flatten = (
  arr: HeroMenuItemType[],
  val: HeroMenuItemType[],
) => val && arr.concat(val);

export const buildHeroMenuItems = (
  heroMenuCategory?: HeroMenuCategoryType[],
): HeroMenuItemTypeEx[] => (heroMenuCategory ? heroMenuCategory
  .map((category) => category.heroMenuItems || [])
  .reduce(flatten, [] as HeroMenuItemType[])
  .map(transformHeroMenuItems)
  : []);
