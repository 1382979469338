import { AppState } from '../../state';
import { initialState, HeroState } from './reducer';

export const getHero = (
  state: AppState,
): HeroState => (
  state.hero
    ? state.hero
    : initialState
);
