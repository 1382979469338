import React, { useEffect } from 'react';
import {
  HeroType,
  HeroMenuItemType,
} from 'src/infrastructure/gatsby/types/hero';
import { useDispatch } from 'react-redux';
import { loadComponent } from 'src/application/core/control/actions';
import HeroDesktop from './desktop';
import HeroMobile from './mobile';
import { Panel } from './desktop/service';
import { HeroWrapper, MainTitle } from './hero.styles';

export type HeroGroupType = {
  title: string;
  tabs: {
    id: string,
    active?: boolean,
    title: string,
    icon: React.ReactNode,
  }[];
};

export type HeroMenuItemTypeEx = HeroMenuItemType & {
  id: string,
  Panel: Panel;
};

const Hero: React.FC<HeroType> = ({
  sectionId,
  title,
  HeroMenuCategory,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadComponent('hero'));
  }, []);
  return (
    <HeroWrapper>
      {/* NOTE: We want to have only one H1 tag for SEO purposes */}
      <MainTitle data-test="hero-title">{title}</MainTitle>
      <HeroDesktop
        sectionId={sectionId}
        HeroMenuCategory={HeroMenuCategory}
      />
      <HeroMobile
        sectionId={sectionId}
        HeroMenuCategory={HeroMenuCategory}
      />
    </HeroWrapper>
  );
};

export default Hero;
