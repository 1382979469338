/* eslint no-confusing-arrow: 0 */
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { GatsbyImageProps } from 'gatsby-image';

import { fonts, media } from 'src/views/theme';
import {
  white,
} from '@shipae/components-sandbox/component/colors';

type BgImageProps = {
  current: boolean;
} & GatsbyImageProps;

export const BgImage = styled(GatsbyImage)<BgImageProps>`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  opacity: 1;
  visibility: ${ (pr) => (pr.current ? 'visible' : 'hidden') };
  opacity: ${ (pr) => (pr.current ? 1 : 0) };
  transition: all 0.5s;

  ${ media.md } {
    display: none;
    animation: none;
    width: 0;
    height: 0;
  }
`;

export const BgColor = styled.div<{color: string}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -3;
  background-color: ${ (pr) => pr.color };

  ${ media.md } {
    display: none;
  }
`;

export const Section = styled.section`
  width: 100%;
  padding: 17rem calc((100% - var(--container-width) - 1rem) / 2);
  padding-bottom: 4rem;
  position: relative;
  height: 70rem;

  ${ media.md } {
    display: none;
    padding: 0;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  box-sizing: content-box;
`;

export const Menu = styled.div`
  padding: 4rem 3.2rem;
  background-color: ${ white(0.75) };
  border-radius: 1.2rem;
  width: 92.8rem;
  backdrop-filter: blur(30px);

  & [data-test='tabs-group-main'],
  & [data-test='tabs-tab-main'] {
    ${ fonts.bodys300 };
  }

  ${ media.md } {
    width: 100%;
    padding: 0;
    background: none;
    backdrop-filter: none;
  }
`;

export const Icon = styled.div`
  width: 4rem;
  height: 4rem;
  background-repeat: no-repeat;
  background-position: center;
`;

export const Loading = styled.p`
  margin-top: 4.8rem;
  ${ fonts.displays200 };
`;
