import { OptionType } from './entities';

export const HERO_ACTIVE_TAB_ID = '[hero] active tab id';
export const HERO_ACTIVE_CATEGORY_ID = '[hero] active category id';

export type SetHeroActiveTabId = {
  type: typeof HERO_ACTIVE_TAB_ID;
  payload: string;
  isMobile?: boolean,
};
export const setHeroActiveTabId = (
  activeTabId: string,
  isMobile?: boolean,
): SetHeroActiveTabId => ({
  type: HERO_ACTIVE_TAB_ID,
  payload: activeTabId,
  isMobile,
});

export type SetHeroActiveCategoryId = {
  type: typeof HERO_ACTIVE_CATEGORY_ID;
  payload: string;
  isMobile?: boolean,
};
export const setHeroActiveCategoryId = (
  activeCategoryId: string,
  isMobile?: boolean,
): SetHeroActiveCategoryId => ({
  type: HERO_ACTIVE_CATEGORY_ID,
  payload: activeCategoryId,
  isMobile,
});

// Track Shipment
export const FORMS_HERO_TRACK_SHIPMENT = '[forms] hero track shipment';
export type SetHeroTrackShipmentFormDataPayload = {
  trackingNumber: {
    value?: string;
    validationError?: string;
  };
  trackUrl: string;
};
export type SetHeroTrackShipmentFormData = {
  type: typeof FORMS_HERO_TRACK_SHIPMENT;
  payload: SetHeroTrackShipmentFormDataPayload;
};
export const setHeroTrackShipmentFormData = (
  data: SetHeroTrackShipmentFormDataPayload,
): SetHeroTrackShipmentFormData => ({
  type: FORMS_HERO_TRACK_SHIPMENT,
  payload: data,
});

// Air and ocean freight
export const FORMS_HERO_AIR_OCEAN = '[forms] hero air ocean';
export type SetHeroAirOceanFormDataPayload = {
  origin: OptionType | OptionType[] | null | undefined,
  destination: OptionType | OptionType[] | null | undefined,
  quoteUrl: string,
};
export type SetHeroAirOceanFormData = {
  type: typeof FORMS_HERO_AIR_OCEAN;
  payload: SetHeroAirOceanFormDataPayload;
};
export const setHeroAirOceanFormData = (
  data: SetHeroAirOceanFormDataPayload,
): SetHeroAirOceanFormData => ({
  type: FORMS_HERO_AIR_OCEAN,
  payload: data,
});

// Last mile delivery
export const FORMS_HERO_LAST_MILE = '[forms] hero last mile';
export type SetHeroLastMileFormDataPayload = {
  firstName?: string,
  email?: string,
  emailValidationError?: string,
  createAccountUrl: string,
  allowCreateAccount?: boolean,
};
export type SetHeroLastMileFormData = {
  type: typeof FORMS_HERO_LAST_MILE;
  payload: SetHeroLastMileFormDataPayload;
};
export const setHeroLastMileFormData = (
  data: SetHeroLastMileFormDataPayload,
): SetHeroLastMileFormData => ({
  type: FORMS_HERO_LAST_MILE,
  payload: data,
});

// International Shopping
export const FORMS_HERO_INT_SHOPPING = '[forms] international shopping';
export type SetHeroIntShoppingFormDataPayload = {
  email?: string,
  emailValidationError?: string,
  country: OptionType | OptionType[] | null | undefined,
  freeAddressUrl: string,
  allowGetFreeAddress?: boolean,
};
export type SetHeroIntShoppingFormData = {
  type: typeof FORMS_HERO_INT_SHOPPING;
  payload: SetHeroIntShoppingFormDataPayload;
};
export const setHeroIntShoppingFormData = (
  data: SetHeroIntShoppingFormDataPayload,
): SetHeroIntShoppingFormData => ({
  type: FORMS_HERO_INT_SHOPPING,
  payload: data,
});

export type HeroAction =
  SetHeroActiveTabId
  | SetHeroActiveCategoryId
  | SetHeroTrackShipmentFormData
  | SetHeroAirOceanFormData
  | SetHeroLastMileFormData
  | SetHeroIntShoppingFormData;
