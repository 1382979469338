import styled from 'styled-components';
import {
  TabPanelPropsType,
} from '@shipae/components-sandbox/component/tabs/panel';
import { media, fonts } from 'src/views/theme';

import {
  g200, black,
} from '@shipae/components-sandbox/component/colors';

export const Loading = styled.p`
  margin-top: 4.8rem;
  ${ fonts.displays200 };
`;

type SectionProps = {
  bgImage: string,
  bgColor: string,
}
export const Section = styled.section<SectionProps>`
  &::before {
    content: ' ';
    ${ (pr) => (pr.bgColor ? `
      background-color: ${ pr.bgColor };
    ` : '') }
    ${ (pr) => (pr.bgImage ? `
      background-image: url('${ pr.bgImage }');
    ` : '') }
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(80px);
    -webkit-backdrop-filter: blur(80px);
    backdrop-filter: blur(80px);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }

  position: relative;
  padding: 14.5rem 1.6rem 3.2rem;
  display: none;

  ${ media.md } {
    display: block;
  }

  ${ media.max(480) } {
    padding-top: 19.3rem;
  }
`;

export const Category = styled.div`
  border: 1px solid ${ g200() };
  border-radius: 1.2rem;
  padding: 1.6rem;
  margin-bottom: 3.2rem;
`;
export const CategoryTitle = styled.div`
  ${ fonts.displays400 };
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
`;

export const Panel = styled.div``;
export const PanelFormTitle = styled.h2`
  ${ fonts.displays200 };
`;
export const PanelContainer = styled.div<TabPanelPropsType>``;
export const Tabs = styled.div`
  display: flex;
  overflow-y: scroll;
  margin-bottom: 3.2rem;
`;
type TabProps = {
  active?: boolean,
};
export const Tab = styled.div<TabProps>`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 1.6rem 1rem;
  cursor: pointer;
  border: 4px solid transparent;
  min-width: 14rem;
  max-width: 14rem;
  border-bottom-color: ${ (pr) => pr.active && black() };
`;
