import { Middleware } from 'redux';
import { Services } from 'src/application/interfaces';
import { validateEmail } from 'application/utils/validators';
import {
  FORMS_HERO_AIR_OCEAN,
  FORMS_HERO_INT_SHOPPING,
  FORMS_HERO_LAST_MILE,
  FORMS_HERO_TRACK_SHIPMENT,
} from './actions';
// import { getOptionValue } from './middleware.utils';
import { OptionType } from './entities';

// track shipment
export const trackingNumberInvalid = (tn: string): string => {
  const errorMsg = 'alphanumeric value only';
  const valid = /^[a-z0-9A-Z,]*$/g.test(tn);
  return valid ? '' : errorMsg;
};

export const buildTrackUrl = (
  tn?: string,
): string => `/track/?trackingno=${
  !tn || trackingNumberInvalid(tn) ? '' : tn
}`;

export const TrackingNumberEntered = ({
  logger,
}: Services): Middleware => () => (next) => (action): void => {
  if (
    action.type === FORMS_HERO_TRACK_SHIPMENT
    && action.payload
    && action.payload.trackingNumber
    && action.payload.trackingNumber.value
  ) {
    const validationError = trackingNumberInvalid(
      action.payload.trackingNumber.value,
    );

    next({
      ...action,
      payload: {
        ...action.payload,
        trackingNumber: {
          ...action.payload.trackingNumber,
          validationError,
        },
        trackUrl: buildTrackUrl(
          action.payload.trackingNumber.value,
        ),
      },
    });

    logger.info(`${
      validationError ? 'InValid' : 'valid'
    } hero section tracking number entered...`);
  } else {
    next(action);
  }
};

// air and ocean freight
export const buildQuoteUrl = (
  origin?: OptionType | OptionType[] | null | undefined,
  destination?: OptionType | OptionType[] | null | undefined,
): string => {
  const staticQuery = 'utm_source=shipa&utm_medium=getquote&utm_campaign=shipa';
  console.info(origin, destination); // eslint-disable-line no-console

  return `https://www.shipafreight.com/?${ staticQuery }`;
  /*
  return `https://www.shipafreight.com/?${ staticQuery }&oc=${
    getOptionValue(origin)
  }&dc=${
    getOptionValue(destination)
  }`;
  */
};

export const OriginOrDesinationEntered = ({
  logger,
}: Services): Middleware => () => (next) => (action): void => {
  if (
    action.type === FORMS_HERO_AIR_OCEAN
    && action.payload
  ) {
    next({
      ...action,
      payload: {
        ...action.payload,
        quoteUrl: buildQuoteUrl(
          action.payload.origin,
          action.payload.destination,
        ),
      },
    });

    logger.info('hero air and ocean: origin or destination entered');
  } else {
    next(action);
  }
};

// Last Mile Delivery
export const buildCreateAccountUrl = (
  firstName?: string,
  email?: string,
): string => `/shipa-delivery-business/work-with-us/?user=${
  firstName || ''
}&email=${
  email || ''
}`;

export const FirstNameOrEmailEntered = ({
  logger,
}: Services): Middleware => () => (next) => (action): void => {
  if (
    action.type === FORMS_HERO_LAST_MILE
    && action.payload
  ) {
    const emailValidationError = action.payload.email
      && validateEmail(action.payload.email);

    next({
      ...action,
      payload: {
        ...action.payload,
        emailValidationError,
        allowCreateAccount: !!emailValidationError,
        createAccountUrl: buildCreateAccountUrl(
          action.payload.firstName,
          action.payload.email,
        ),
      },
    });

    logger.info('hero last mile delivery: firstName or email entered');
  } else {
    next(action);
  }
};

// International Shopping
const AUTHENTIC_UFL = 'https://account.shipa.com';
const APP_SHIPAMALL_URL = 'https://app.shipamall.com';
export const buildFreeAddressUrl = (
  email?: string,
  // country?: OptionType | OptionType[] | null | undefined,
): string => `${ AUTHENTIC_UFL }/t/customer/account/signup?returnUrl=${
  APP_SHIPAMALL_URL
}&client_id=shipamall-web&email=${ email }`;

export const IntShoppingFormDataChanged = ({
  logger,
}: Services): Middleware => () => (next) => (action): void => {
  if (
    action.type === FORMS_HERO_INT_SHOPPING
    && action.payload
  ) {
    const emailValidationError = action.payload.email
      && validateEmail(action.payload.email);

    next({
      ...action,
      payload: {
        ...action.payload,
        emailValidationError,
        allowGetFreeAddress: !!emailValidationError,
        freeAddressUrl: buildFreeAddressUrl(
          action.payload.email,
          // action.payload.country,
        ),
      },
    });

    logger.info('hero international shopping: email or country entered');
  } else {
    next(action);
  }
};

export default [
  TrackingNumberEntered,
  OriginOrDesinationEntered,
  FirstNameOrEmailEntered,
  IntShoppingFormDataChanged,
];
