import { fonts, media } from 'src/views/theme';
import styled from 'styled-components';

export const HeroWrapper = styled.div`
  position: relative;
`;

export const MainTitle = styled.h1`
  position: absolute;
  z-index: 1;
  ${ fonts.displayl200 };
  ${ /* eslint-disable-next-line max-len */'' }
  padding: 4.8rem calc((100% - var(--container-width) - 1rem) / 2) 3.2rem calc((100% - var(--container-width) - 1rem) / 2);

  ${ media.md } {
    ${ fonts.displaym200 };
    margin-bottom: 4.3rem;
    padding: 3.2rem 1.6rem;
  }

  ${ media.sm } {
    font-size: 4rem;
    font-weight: 300;
    font-family: ${ `-apple-system,
    BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"` };
  }
`;
